<ion-header>
    <ion-toolbar>
        <ion-title>Status Logs</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="closeStatusLogModal()">Close</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content class="ion-padding">
    <ion-grid class="ion-padding">
        <ion-row>
            <ion-col>
                <ion-text>
                    Date: <strong>{{ date | date : "MM/dd/YYYY" }}</strong>
                </ion-text> <br/>
                <ion-text>Name: <strong>{{ name }}</strong></ion-text> <br/>
                <ion-text>Timezone: <strong>{{ timezone }}</strong></ion-text>
            </ion-col>
            <ion-col size="auto">
                <ion-text [color]="totalBioBreakHours > 0.5 ? 'danger' : 'default'">Bio Break: <strong>{{ totalBioBreakHours | number : '1.2-2' }} hr(s)</strong></ion-text> <br/>
                <ion-text [color]="totalLunchBreakHours > 1 ? 'danger' : 'default'">Lunch Break: <strong>{{ totalLunchBreakHours | number : '1.2-2' }} hr(s)</strong></ion-text>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ngx-datatable
        class="ion-padding"
        [rows]="statusLogs"
        columnMode="flex"
        [scrollbarH]="false"
        [scrollbarV]="false"
        [headerHeight]="40"
        [rowHeight]="50"
    >
            <ngx-datatable-column name="Status" prop="status" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <ion-text *ngIf="row.status == 'online'" class="status online">Online</ion-text>
                    <ion-text *ngIf="row.status == 'in_a_meeting'" class="status in_a_meeting">In a Meeting</ion-text>
                    <ion-text *ngIf="row.status == 'busy'" class="status busy">Busy</ion-text>
                    <ion-text *ngIf="row.status == 'lunch_break'" class="status lunch_break">Lunch Break</ion-text>
                    <ion-text *ngIf="row.status == 'bio_break'" class="status bio_break">Bio Break</ion-text>
                    <ion-text *ngIf="row.status == 'official_business'" class="status official_business">Official Business</ion-text>
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Start" prop="start_time" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.start_time | date : "shortTime" : user.timezone_offset }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="End" prop="end_time" [flexGrow]="1">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.end_time | date : "shortTime" : user.timezone_offset }}
                </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column name="Duration" [sortable]="false" prop="total_hours" [flexGrow]="1" >
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{ row.total_hours | number : '1.2-2' }} hr(s)
                </ng-template>
            </ngx-datatable-column>
    </ngx-datatable>
</ion-content>